import React from 'react';
import Select from 'react-select';
import DRServicesApi from '../../api/DRServicesApi';
import Formatter from '../../helpers/Formatter';
import queryString from 'query-string';

class FipePage extends React.Component {

  constructor(props) {
    super(props);

    this.isLoadingById = false;
    this.types = [];
    this.brands = [];
    this.models = [];
    this.preyears = [];
    this.years = [];
    this.value = null;
    this.values = [];

    this.state = {
      typesLoaded: false,
      brandsLoaded: false,
      modelsLoaded: false,
      preyearsLoaded: false,
      yearsLoaded: false,
      fipeLoaded: false,

      type: null,
      brand: null,
      model: null,
      preyear: null,
      year: null,
      fipe: null,

      error: null
    };

    this.handleChange = this.handleChange.bind(this);
    this.sendFipeMessage = this.sendFipeMessage.bind(this);
  }

  getId() {
    const params = queryString.parse(window.location.search);
    return params.id || null;
  }

  componentDidMount() {
    const id = this.getId();
    if (id) {
      this.loadById(parseInt(id));
    } else {
      this.loadTypes();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.isLoadingById) {
      this.isLoadingById = false;
      return;
    }

    if (prevState.type !== this.state.type) {
      this.loadBrands();
    }
    if (this.state.brand && prevState.brand !== this.state.brand) {
      this.loadPreYears();
    }
    if (this.state.preyear && prevState.preyear !== this.state.preyear) {
      this.loadModels();
    }
    if (this.state.model && prevState.model !== this.state.model) {
      this.loadYears();
    }
    if (this.state.year && prevState.year !== this.state.year) {
      this.loadValues();
    }
  }

  handleChange(item, event) {
    const newState = {
      [event.name]: item
    };

    switch (event.name) {
      case 'type':
        this.brands = [];
        this.preyears = [];
        this.models = [];
        this.years = [];
        newState.brandsLoaded = false;
        newState.preyearsLoaded = false;
        newState.modelsLoaded = false;
        newState.yearsLoaded = false;
        newState.fipeLoaded = false;
        newState.brand = null;
        newState.preyear = null;
        newState.model = null;
        newState.year = null;
        newState.fipe = null;
        break;
      case 'brand':
        this.preyears = [];
        this.models = [];
        this.years = [];
        newState.preyearsLoaded = false;
        newState.modelsLoaded = false;
        newState.yearsLoaded = false;
        newState.fipeLoaded = false;
        newState.preyear = null;
        newState.model = null;
        newState.year = null;
        newState.fipe = null;
        break;
      case 'preyear':
        this.models = [];
        this.years = [];
        newState.modelsLoaded = false;
        newState.yearsLoaded = false;
        newState.fipeLoaded = false;
        newState.model = null;
        newState.year = null;
        newState.fipe = null;
        break;
      case 'model':
        this.years = [];
        newState.yearsLoaded = false;
        newState.fipeLoaded = false;
        newState.year = null;
        newState.fipe = null;
        break;
      case 'year':
        newState.fipeLoaded = false;
        newState.fipe = null;
        break;
      default:
        console.error('change not expected');
    }

    this.setState(newState);
  }

  loadById(id) {
    DRServicesApi.getYearData(id)
      .then(body => {
        if (body.success) {
          this.isLoadingById = true;
          this.types = body.data.types;
          this.brands = body.data.brands;
          this.models = body.data.models;
          this.preyears = body.data.preyears;
          this.years = body.data.years;
          this.values = body.data.values;
          this.value = this.values[0];

          const type = this.types.find(type => { return type.code === body.data.selectedIds.type_code; });
          const brand = this.brands.find(brand => { return brand.id === body.data.selectedIds.brand_id; });
          const preyear = this.preyears.find(preyear => { return preyear.value === body.data.selectedIds.preyear; });
          const model = this.models.find(model => { return model.id === body.data.selectedIds.vehicle_model_id; });
          const year = this.years.find(year => { return year.id === id; });

          this.setState({
            typesLoaded: true,
            brandsLoaded: true,
            preyearsLoaded: true,
            modelsLoaded: true,
            yearsLoaded: true,
            fipeLoaded: true,
            type: {value: type.slug, label: type.name},
            preyear: preyear,
            brand: {value: brand.id, label: brand.name},
            model: {value: model.id, label: model.name},
            year: {value: year.id, label: year.name},
            fipe: this.value.code_fipe,
          });
        } else {
          console.error(body);
          this.setState({hasError: true});
        }
      })
      .catch(error => {
        console.error(error);
        this.setState({hasError: true});
      });
  }

  loadTypes() {
    if (this.state.typesLoaded) {
      return;
    }

    DRServicesApi.getTypes()
      .then(body => {
        if (body.success) {
          this.types = body.data;

          this.setState({typesLoaded: true});
        } else {
          this.setState({hasError: true});
        }
      })
      .catch(error => {
        this.setState({hasError: true});
      });
  }

  loadBrands() {
    DRServicesApi.getBrands(this.state.type.value)
      .then(body => {
        if (body.success) {
          this.brands = body.data;

          this.setState({brandsLoaded: true});
        } else {
          console.error(body);
          this.setState({error: body.message});
        }
      })
      .catch(error => {
        this.setState({error: error.toString()});
      });
  }

  loadPreYears() {
    DRServicesApi.getPreYears(this.state.brand.value)
      .then(body => {
        if (body.success) {
          this.preyears = body.data;

          this.setState({preyearsLoaded: true});
        } else {
          console.error(body);
          this.setState({error: body.message});
        }
      })
      .catch(error => {
        console.error(error);
        this.setState({error: error.toString()});
      });
  }

  loadModels() {
    DRServicesApi.getModels(this.state.brand.value, this.state.preyear.value)
      .then(body => {
        if (body.success) {
          this.models = body.data;

          this.setState({modelsLoaded: true});
        } else {
          this.setState({error: body.message});
        }
      })
      .catch(error => {
        console.error(error);
        this.setState({error: error.toString()});
      });
  }

  loadYears() {
    DRServicesApi.getYears(this.state.model.value)
      .then(body => {
        if (body.success) {
          this.years = body.data;

          let year = this.years.find(item => item.year == this.state.preyear.value);
          console.log(this.state.preyear.value, year);

          this.setState({
            year: year ? {value: year.id, label: year.name} : null,
            yearsLoaded: true
          });
        } else {
          console.error(body);
          this.setState({error: body.message});
        }
      })
      .catch(error => {
        console.error(error);
        this.setState({error: error.toString()});
      });
  }

  loadValues() {
    DRServicesApi.getValues(this.state.year.value)
      .then(body => {
        if (body.success) {
          this.value = body.data[0];
          this.values = body.data;

          this.setState({
            fipeLoaded: true,
            fipe: this.value.code_fipe
          });
        } else {
          this.setState({error: body.message});
        }
      })
      .catch(error => {
        this.setState({error: error.toString()});
      });
  }

  renderType() {
    const types = this.types.map(item => ({value: item.slug, label: item.name}));
    const isLoading = !this.state.typesLoaded;

    return <li className="list-group-item">
      <div className="form-group">
        <label htmlFor="type"><strong>Tipo</strong></label>
        <Select id="type"
                name="type"
                value={this.state.type}
                options={types}
                isLoading={isLoading}
                placeholder={isLoading ? 'Carregando...' : 'Selecione'}
                onChange={this.handleChange}
        />
      </div>
    </li>;
  }

  renderBrand() {
    const brands = this.brands.map(item => ({value: item.id, label: item.name}));
    const isLoading = !this.state.brandsLoaded;

    return <li className="list-group-item">
      <div className="form-group">
        <label htmlFor="brand"><strong>Marca</strong></label>
        <Select id="brand"
                name="brand"
                value={this.state.brand}
                options={brands}
                isLoading={isLoading}
                placeholder={isLoading ? 'Carregando...' : 'Selecione'}
                onChange={this.handleChange}
        />
      </div>
    </li>;
  }

  renderPreYears() {
    const isLoading = !this.state.preyearsLoaded;

    return <li className="list-group-item">
      <div className="form-group">
        <label htmlFor="preyear"><strong>Ano Modelo</strong></label>
        <Select id="preyear"
                name="preyear"
                value={this.state.preyear}
                options={this.preyears}
                menuPlacement="top"
                isLoading={isLoading}
                placeholder={isLoading ? 'Carregando...' : 'Selecione'}
                onChange={this.handleChange}
        />
      </div>
    </li>;
  }

  renderModels() {
    const models = this.models.map(item => ({value: item.id, label: item.name}));
    const isLoading = !this.state.modelsLoaded;

    return <li className="list-group-item">
      <div className="form-group">
        <label htmlFor="model"><strong>Modelo</strong></label>
        <Select id="model"
                name="model"
                value={this.state.model}
                options={models}
                isLoading={isLoading}
                placeholder={isLoading ? 'Carregando...' : 'Selecione'}
                onChange={this.handleChange}
        />
      </div>
    </li>;
  }

  renderYears() {
    const years = this.years.map(item => ({value: item.id, label: item.name}));
    const isLoading = !this.state.yearsLoaded;

    return <li className="list-group-item">
      <div className="form-group">
        <label htmlFor="year"><strong>Ano Modelo</strong></label>
        <Select id="year"
                name="year"
                value={this.state.year}
                options={years}
                menuPlacement="top"
                isLoading={isLoading}
                placeholder={isLoading ? 'Carregando...' : 'Selecione'}
                onChange={this.handleChange}
        />
      </div>
    </li>;
  }

  renderValue() {
    return <li className="list-group-item">
      <div className="row">
        <div className="col">
          <label><strong>Código FIPE</strong></label>
          <div>{this.state.fipeLoaded ? this.value.code_fipe : 'Carregando...'}</div>
        </div>
        <div className="col">
          <label><strong>Valor atual</strong></label>
          <div>{this.state.fipeLoaded ? Formatter.asCurrency(this.value.value) : 'Carregando...'}</div>
        </div>
      </div>
    </li>;
  }

  renderValues() {
    const values = this.values.map(item => ({value: item.id, label: `${item.reference} - ${Formatter.asCurrency(item.value)}`}));
    values.shift();

    if (!values.length) {
      return;
    }

    const isLoading = !this.state.fipeLoaded;

    return <li className="list-group-item">
      <div className="form-group">
        <label htmlFor="values"><strong>Valores em outros meses</strong></label>
        <Select id="values"
                name="values"
                options={values}
                menuPlacement="top"
                isLoading={isLoading}
                placeholder={isLoading ? 'Carregando...' : 'Clique para visualizar'}
        />
      </div>
    </li>;
  }

  close() {
    window.close();
  }

  sendFipeMessage() {
    if (!window.opener) {
      return;
    }

    const message = {
      id: this.state.year.value,
      type: this.state.type.label,
      brand: this.state.brand.label,
      model: this.state.model.label,
      year: this.state.year.label,
      current_value: {
        fipe: this.value.code_fipe,
        value: this.value.value,
        formatted_value: Formatter.asCurrency(this.value.value),
        reference: this.value.reference
      },
      values: this.values.map(value => {
        return {
          fipe: value.code_fipe,
          value: value.value,
          formatted_value: Formatter.asCurrency(value.value),
          reference: value.reference
        }
      })
    };

    window.opener.postMessage(message, '*');

    this.close();
  }

  render() {
    if (this.state.error) {
      return <h3>{this.state.error}</h3>;
    }

    if (!this.state.typesLoaded) {
      return <h3>Carregando...</h3>;
    }

    return (
      <form className="needs-validation" noValidate>
        <div className="card mt-3 mb-3">
          <h5 className="card-header">Consulta FIPE</h5>
          <ul className="list-group list-group-flush">
            {this.renderType()}
            {this.state.type && this.renderBrand()}
            {this.state.type && this.state.brand && this.renderPreYears()}
            {this.state.type && this.state.brand && this.state.preyear && this.renderModels()}
            
            <div className="d-none">
            {this.state.type && this.state.brand && this.state.preyear && this.state.model && this.renderYears()}
            </div>
            
            {this.state.type && this.state.brand && this.state.preyear && this.state.model && this.state.year && this.renderValue()}
            {this.state.type && this.state.brand && this.state.preyear && this.state.model && this.state.year && this.renderValues()}

            <li className="list-group-item">
              <div className="row">
                <div className="col">
                  <button type="button" onClick={this.close} className="btn btn-light btn-block">Fechar</button>
                </div>
                <div className="col">
                  {this.state.fipe && <button onClick={this.sendFipeMessage} type="button"
                                              className="btn btn-success btn-block">Selecionar</button>}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </form>
    );
  }
}

export default FipePage;