import React from 'react';
import DeviceCard from './DeviceCard';
import Formatter from '../../helpers/Formatter';
import VelotrackApi from '../../api/VelotrackApi';

class MainPage extends React.Component {
  constructor(props) {
    super(props);

    this.saveInterval = setInterval(() => {
      if (!this.state.changed) {
        return;
      }

      this.setState({
        changed: false
      });

      this.save();
    }, 500);

    this.state = {
      changed: false,
      isLoaded: false,
      hasError: false
    };

    this.devices = [];

    this.handleChange = this.handleChange.bind(this);
    this.handleDeviceChange = this.handleDeviceChange.bind(this);
  }

  componentWillMount() {
    VelotrackApi.getMainData()
      .then(data => {
        if (data === null) {
          this.setState({
            hasError: true
          });
        } else {
          this.devices = data.devices;

          this.setState({
            isLoaded: true,
            cellphone_full: Formatter.asCellphonePTBR(data.cellphone_full),
            fields: data.fields,
          });
        }
      })
      .catch(error => {
        this.setState({
          hasError: true
        });
      });
  }

  componentWillUnmount() {
    clearInterval(this.saveInterval);
  }

  save() {
    const data = {
      cellphone_full: this.state.cellphone_full,
      fields: this.state.fields
    };

    VelotrackApi.updateMainData(data)
      .catch(error => {
        this.setState({
          hasError: true
        });
      });
  }

  handleChange(event) {
    const target = event.target;
    const name = target.name;

    let value = target.value;

    if (name === 'cellphone_full') {
      value = Formatter.asCellphonePTBR(value);

      this.setState({
        cellphone_full: value,
        changed: true,
      });
    } else {
      const fields = this.state.fields;
      for (const [index, field] of fields.entries()) {
        if (field.name.toString() === name.toString()) {
          fields[index]['value'] = value;
          break;
        }
      }

      this.setState({
        fields,
        changed: true,
      });
    }
  }

  handleDeviceChange(deviceUpdated) {
    for (const [index, device] of this.devices.entries()) {
      if (device.iddevice === deviceUpdated.iddevice) {
        this.devices[index] = deviceUpdated;
        break;
      }
    }
  }

  renderFields() {
    return this.state.fields.map((field, index) => {
      return <li className="list-group-item" key={index}>
        <div className="form-group">
          <label htmlFor="license_expiration_date">{field.label}</label>
          <input type="date" className="form-control"
                 id={field.name} name={field.name} value={field.value}
                 onChange={this.handleChange}/>
        </div>
      </li>;
    });
  }

  renderCustomer() {
    return <div className="card mt-3 mb-3">
      <h5 className="card-header">Seus Dados</h5>
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          <div className="form-group">
            <label htmlFor="cellphone_full">Celular (WhatsApp)</label>
            <input type="tel" className="form-control" id="cellphone_full" name="cellphone_full" required
                   maxLength="15" pattern="\([0-9]{2}\) [0-9]{5}-[0-9]{4}" placeholder="(__) _____-____"
                   value={this.state.cellphone_full}
                   onChange={this.handleChange}/>
          </div>
        </li>
        {this.renderFields()}
      </ul>
    </div>;
  }

  renderDevices() {
    return this.devices.map(device => {
      return <DeviceCard key={device.iddevice}
                         device={device}
                         onChange={this.handleDeviceChange}/>
    });
  }

  render() {
    if (this.state.hasError) {
      return <h1>Alguma coisa deu errada. Tente novamente mais tarde.</h1>;
    }

    return (
      <form className="needs-validation" noValidate>
        {this.state.isLoaded && this.renderCustomer()}
        {this.state.isLoaded && this.renderDevices()}
      </form>
    );
  }
}

export default MainPage;