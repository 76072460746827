class Formatter {
  static asCellphonePTBR(number) {
    if (!number) {
      return '';
    }

    const value = number.replace(/\D/g, '')
      .replace(/^(\d{1})/, '($1')
      .replace(/^(\(\d{2})(\d)/, '$1) $2');

    return value.replace(/(\d{5})(\d{1,4})/, '$1-$2');
  }

  static asCurrency(value, settings) {
    settings = Object.assign({
      format: 'pt-BR',
      localeOptions: {
        style: 'currency',
        currency: 'BRL',
      }
    }, settings);

    if (typeof value !== 'string') {
      value = value.toString();
    }

    if (value.indexOf(',') >= 0) {
      value = value.replace('.', '').replace(',', '.');
    }

    if (value.length) {
      return parseFloat(value).toLocaleString(settings.format, settings.localeOptions);
    }
  }
}

export default Formatter;