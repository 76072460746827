import queryString from 'query-string';
import axios from 'axios';

class VelotrackApi {
  static getUid() {
    const params = queryString.parse(window.location.search);
    if (!params.uid) {
      throw Error('Token is invalid');
    }

    return params.uid;
  }

  static getIdcustomer() {
    const params = queryString.parse(window.location.search);
    if (!params.idcustomer) {
      throw Error('IdCustomer is invalid');
    }

    return params.idcustomer;
  }

  static getUserAgent() {
    const params = queryString.parse(window.location.search);
    if (!params.userAgent) {
      throw Error('UserAgent is invalid');
    }

    return params.userAgent;
  }

  static request(method, endpoint, data) {
    const url = `https://track.velotrack.com.br/api/index.php/${endpoint}`;
    // const url = `http://local.velotrack.com.br/api/index.php/${endpoint}`;

    const options = {
      method,
      url,
      mode: 'no-cors',
      headers: {
        uid: this.getUid(),
        browser: this.getUserAgent(),
        'Content-Type': 'application/json',
      }
    };

    if (data) {
      options.data = data;
    }

    return axios(options)
      .then(response => {
        if (response.status === 200) {
          return response.data;
        }

        return null;
      })
      .catch(error => {
        return null;
      });
  }

  static get(endpoint) {
    return this.request('GET', endpoint);
  }

  static post(endpoint, data) {
    return this.request('POST', endpoint, data);
  }

  static put(endpoint, data) {
    return this.request('PUT', endpoint, data);
  }

  static getMainData() {
    return this.get(`mobile/${this.getIdcustomer()}/main`);
  }

  static updateMainData(data) {
    return this.put(`mobile/${this.getIdcustomer()}/main`, data);
  }

  static updateMainDevice(data) {
    return this.put(`mobile/${this.getIdcustomer()}/main/${data.iddevice}/device`, data);
  }
}

export default VelotrackApi;