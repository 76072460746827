import React from 'react';
import MainPage from './pages/main/MainPage';
import FipePage from './pages/fipe/FipePage';
import ErrorBoundary from './pages/error/ErrorBoundary';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import './App.css';

function App() {
  return (
    <div className="container-fluid">
      <ErrorBoundary>
        <Router>
          <Switch>
            <Route path="/cliente">
              <MainPage/>
            </Route>
            <Route path="/fipe">
              <FipePage/>
            </Route>
          </Switch>
        </Router>
      </ErrorBoundary>
    </div>
  );
}

export default App;
