import axios from 'axios';

class DRServicesApi {

  static version = 1;
  static token = 'XTBDa8ejvj4xvuggTxUN5m73llojQm9z';

  static request(method, endpoint, data) {
    // const url = `http://services.dr.test/v${this.version}/${endpoint}`;
    const url = `https://services.dr.com.br/v${this.version}/${endpoint}`;

    const options = {
      method,
      url,
      headers: {
        'Authorization': `Bearer ${this.token}`,
        'Content-Type': 'application/json'
      }
    };

    if (data) {
      options.data = data;
    }

    return axios(options)
      .then(response => {
        if (response.status === 200) {
          return response.data;
        }

        return null;
      })
      .catch(error => {
        return null;
      });
  }

  static get(endpoint) {
    return this.request('GET', endpoint);
  }

  static post(endpoint, data) {
    return this.request('POST', endpoint, data);
  }

  static put(endpoint, data) {
    return this.request('PUT', endpoint, data);
  }

  static getTypes() {
    return this.get(`fipe/tipos`);
  }

  static getReferences() {
    return this.get(`fipe/referencias`);
  }

  static getBrands(type) {
    return this.get(`fipe/${type}/marcas`);
  }

  static getPreYears(brand) {
    return this.get(`fipe/anos?brand=${brand}`);
  }

  static getModels(brand, preyear) {
    return this.get(`fipe/${brand}/modelos?preyear=${preyear}`);
  }

  static getYears(model) {
    return this.get(`fipe/${model}/anos`);
  }

  static getValue(reference, year) {
    return this.get(`fipe/${reference}/${year}/valor`);
  }

  static getValues(year) {
    return this.get(`fipe/${year}/valores`);
  }

  static getYearData(year) {
    return this.get(`fipe/${year}/dados`);
  }
}

export default DRServicesApi;