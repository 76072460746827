import React from 'react';
import Toggle from 'react-toggle';
import VelotrackApi from "../../api/VelotrackApi";

class DeviceCard extends React.Component {
  constructor(props) {
    super(props);

    this.saveInterval = setInterval(() => {
      if (!this.state.changed || this.state.isRunning) {
        return;
      }

      this.setState({
        changed: false
      });

      this.save();
    }, 500);

    this.state = props.device;
    this.state.odometer_value = this.state.odometer_value || '';
    this.state.changed = false;
    this.state.isRunning = false;

    this.handleChange = this.handleChange.bind(this);
  }

  save() {
    this.setState({
      isRunning: true
    });

    const data = {
      iddevice: this.state.iddevice,
      odometer_value: this.state.odometer_value,
      ignition_alert: this.state.ignition_alert,
      trip_report: this.state.trip_report,
      fields: this.state.fields
    };

    VelotrackApi.updateMainDevice(data)
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        this.setState({
          isRunning: false
        });
      });
  }

  componentWillUnmount() {
    clearInterval(this.saveInterval);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.props.onChange(this.state);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'odometer_value' || name === 'ignition_alert' || name === 'trip_report') {
      this.setState({
        [name]: value,
        changed: true,
      });
    } else {
      const fields = this.state.fields;
      for (const [index, field] of fields.entries()) {
        if (field.name.toString() === name.toString()) {
          fields[index]['value'] = value;
          break;
        }
      }

      this.setState({
        fields,
        changed: true,
      });
    }
  }

  renderFields() {
    return this.state.fields.map(field => {
      const id = `${this.state.iddevice}-${field.name}`;
      const value = field.value || '';
      const type = field.type === 'D' ? 'date' : 'number';

      return <li className="list-group-item" key={id}>
        <div className="form-group">
          <label htmlFor={id}>{field.label}</label>
          <input type={type} className="form-control"
                 id={id} name={field.name} value={value}
                 onChange={this.handleChange}/>
        </div>
      </li>;
    });
  }

  render() {
    return <div className="card mb-3" key={this.state.iddevice}>
      <h5 className="card-header">{this.state.vehicle_code} - {this.state.description}</h5>
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          <div className="form-group">
            <label htmlFor="odometer">Quilometragem atual</label>
            <input type="number" className="form-control" id="odometer"
                   value={this.state.odometer} readOnly={true}/>
          </div>
        </li>

        <li className="list-group-item">
          <div className="form-group">
            <label htmlFor="odometer_value">Atualizar quilometragem</label>
            <input type="number" className="form-control" id="odometer_value" name="odometer_value"
                   value={this.state.odometer_value}
                   onChange={this.handleChange}/>
          </div>
        </li>

        {this.renderFields()}

        <li className="list-group-item">
          <div className="row">
            <div className="col">
              <label htmlFor="ignition_alert">Alerta de ignição</label>
              <Toggle defaultChecked={this.state.ignition_alert}
                      icons={false} id="ignition_alert" name="ignition_alert"
                      onChange={this.handleChange}/>
            </div>
            <div className="col mb-3">
              <label htmlFor="trip_report">Relatório de viagem</label>
              <Toggle defaultChecked={this.state.trip_report}
                      icons={false} id="trip_report" name="trip_report"
                      onChange={this.handleChange}/>
            </div>
          </div>
        </li>
      </ul>
    </div>
  }
}

export default DeviceCard;